@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;700&family=Sora:wght@400;700&display=swap");

.heading {
  @apply font-sora font-bold tracking-wider uppercase;
}

.reading-width {
  @apply container mx-auto;
}

@media (min-width: 768px) {
  .col-count-2 {
    column-count: 2;
    column-gap: 20px;
  }
}

:root {
  --swiper-navigation-color: #0a1eb4;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-color: #fff;
}
